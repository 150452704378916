<template>
  <div class="charge-chart">
    <date-and-park-choose @paramschange="(val) => $emit('paramschange', val)"/>
    <div class="chart-wrap">
      <div ref="graph"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import DateAndParkChoose from './DateAndParkChoose'
export default {
  components: {
    DateAndParkChoose
  },
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    total: {
      type: [String, Number],
      default: 0
    }
  },
  data () {
    return {
      chart: null, // 图表实例
      dataMap: {}, // 各个数据的映射
      noObserve: Object.seal({
        chart: null
      })
    }
  },
  mounted () {
    this.genDataMap(this.chartData)
    this.renderChart(this.chartData)
  },
  methods: {
    // 渲染图表
    renderChart (data) {
      const option = {
        color: ['#F35129', '#FCCA1E', '#2C5EF3'],
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)'
        },
        legend: {
          orient: 'vertical',
          left: '0',
          y: 'center',
          formatter: (item) => {
            return `${item} ¥${this.dataMap[item].value}  ${this.dataMap[item].rate}`
          }
        },
        series: [
          {
            // name: '实收',
            type: 'pie',
            radius: [50, 100],
            center: ['70%', '45%'],
            roseType: 'radius',
            itemStyle: {
              borderRadius: 0
            },
            label: {
              show: false
            },
            emphasis: {
              label: {
                show: false
              }
            },
            data
          }
        ]
      }
      const chart = this.noObserve.chart = echarts.init(this.$refs.graph)
      chart.setOption(option)
    },
    // 获取各个数据
    genDataMap (arr) {
      arr.forEach(item => {
        this.dataMap[item.name] = {
          rate: `${this.total && ((item.value / this.total) * 100).toFixed(2)}%`,
          value: item.value
        }
      })
    }
  },
  watch: {
    chartData: {
      handler (val) {
        if (val.length) {
          this.genDataMap(val)
          this.renderChart(val)
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.charge-chart {
  position: relative;
  .chart-wrap{
    width: 448px;
    height: 250px;
    position: absolute;
    right: 0;
    top: 68px;
    div{
      width: 100%;
      height: 100%;
    }
  }
}
</style>
