<template>
  <div class="widget-weelcome">
    <div class="content">
      <p class="say-hello">{{pmOrAmStr}}好，<span class="user-name" :title="userInfo.userName">{{userInfo.userName}}</span></p>
      <p class="time-and-message">
        {{genDate()}}<br>
        欢迎回到停车场管理云平台
      </p>
    </div>
    <img class="weelcome-bg" src="@/assets/widget/weelcome_bg.png" height="270" alt="">
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('GLOBAL', ['userInfo']),

    pmOrAmStr () {
      const date = new Date()
      const hours = date.getHours()
      // eslint-disable-next-line
      if (6 <= hours && hours < 12) {
        return '早上'
      }
      // eslint-disable-next-line
      if (12 <= hours && hours < 18) {
        return '下午'
      }
      return '晚上'
    }
  },

  methods: {
    genDate () {
      const date = new Date()
      const str = '日一二三四五六'
      return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日，星期${str[date.getDay()]}`
    }
  }
}
</script>

<style lang="scss" scoped>
.widget-weelcome {
  position: relative;
  height: 270px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  padding-top: 60px;
  .user-name{
    display: inline-block;
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: bottom;
  }
}

.weelcome-bg {
  position: absolute;
  bottom: 0;
  right: 0;
}

.say-hello {
  font-size: 27px;
}

.time-and-message {
  margin-top: 24px;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}

.content {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: rgb(231 239 253);
  padding: 50px 40px;
}
</style>
