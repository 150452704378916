<template>
  <div class="widget-parkinglot-summary">
    <h3 class="widget-title mb-10">停车场概况</h3>
    <div class="summary-wrap">
      <Summary v-for="list in summaryList" :key="list.name" :summary-data="list"/>
    </div>
  </div>
</template>

<script>
import Summary from './Summary.vue'
import { queryCarParkOverview } from '@/api/index'
const summaryList = [
  {
    name: '停车场总数',
    img: require('@/assets/widget/tck_blue.svg'),
    count: 0
  },
  {
    name: '出入口总数',
    img: require('@/assets/widget/entrance.svg'),
    count: 0
  },
  {
    name: '车道总数',
    img: require('@/assets/widget/roadway.svg'),
    count: 0
  },
  {
    name: '车辆总数',
    img: require('@/assets/widget/cheliang_blue.svg'),
    count: 0
  }
]
export default {
  components: {
    Summary
  },

  data () {
    return {
      summaryList: JSON.parse(JSON.stringify(summaryList))
    }
  },
  mounted () {
    this.getOverview()
  },
  methods: {
    async getOverview () {
      const { data, code } = await queryCarParkOverview()
      if (code === '0') {
        const { allRegionCarSum, entranceSum, parkingSum, roadwaySum } = data
        summaryList[0].count = parkingSum
        summaryList[1].count = entranceSum
        summaryList[2].count = roadwaySum
        summaryList[3].count = allRegionCarSum
        this.summaryList = summaryList
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.summary-wrap {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
</style>
