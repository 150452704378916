<template>
  <div>
    <h3 class="widget-title mb-10">车流量趋势</h3>
    <div class="content widget-card">
      <date-and-park-choose @paramschange="handleParamsChange"/>
      <div class="graph">
        <TrafficVolumeChart :chart-data="chartData" :chart-x-axis="chartXAxis" :isSinglePark="isSinglePark"/>
      </div>
    </div>
  </div>
</template>

<script>
import TrafficVolumeChart from './TrafficVolumeChart.vue'
import DateAndParkChoose from '../ChargeSummary/DateAndParkChoose'
import { fetchVehicleFlowStats, fetchEntrances } from '@/api/index'
import { utcTimeToIsoTime } from '@hui-pro/utils'
import moment from 'moment'
export default {
  components: {
    TrafficVolumeChart,
    DateAndParkChoose
  },
  data () {
    return {
      queryParams: {
        beginTime: '',
        endTime: '',
        carCategory: '-1',
        ctp: '-1',
        eid: '',
        entranceIds: [],
        pid: '',
        requestTime: '',
        // roadwayIds: [],
        statsTarget: 'entrance',
        ttp: 'CUSTOM'
      },
      chartData: [],
      chartXAxis: [],
      isSinglePark: false // 是否为单个停车库的查询
    }
  },
  mounted () {
    this.getVehicleCounts()
  },
  methods: {
    // 处理参数变化
    handleParamsChange (params) {
      const { parkIds } = params
      if (parkIds) {
        this.getEntrance(parkIds[0])
        this.isSinglePark = true
        return
      }
      this.getVehicleCounts(params)
      this.isSinglePark = false
    },
    // 获取车流量统计数据
    async getVehicleCounts (params) {
      this.queryParams = {
        ...this.queryParams,
        ...params
      }
      this.queryParams = {
        ...this.queryParams,
        requestTime: utcTimeToIsoTime(Date.now()),
        endTime: utcTimeToIsoTime(moment(this.queryParams.endTime ? this.queryParams.endTime : new Date()).format('YYYY-MM-DD HH:mm:ss')),
        beginTime: utcTimeToIsoTime(moment(this.queryParams.beginTime ? this.queryParams.beginTime : new Date()).format('YYYY-MM-DD HH:mm:ss'))
      }

      const { code, data } = await fetchVehicleFlowStats(this.queryParams)
      if (code === '0') {
        this.chartData = data ? (this.isSinglePark ? data.totalList : data.parkDataList) : []
        this.chartXAxis = data ? data.showDateList : []
      }
    },
    // 获取出入口列表
    // getRoadway (parkId) {
    //   fetchRoadways(parkId).then(({ data, success }) => {
    //     if (success) {
    //       this.roadways = data.list || []
    //       this.queryParams.roadwayIds = this.roadways.map(item => {
    //         return item.id
    //       })
    //     }
    //   })
    // },
    /**
     * @description 获取出入口列表
     * @author zhengjunling
     */
    getEntrance (parkId) {
      if (!parkId) {
        this.queryParams.eid = ''
        this.queryParams.pid = ''
        this.getVehicleCounts(this.queryParams)
        return
      }
      this.queryParams.pid = parkId
      fetchEntrances(parkId).then(({ data, code }) => {
        if (code === '0') {
          const entranceIds = data.list ? data.list.map(item => {
            return item.id
          }) : []
          this.queryParams.eid = entranceIds
          this.queryParams.entranceIds = entranceIds
          this.getVehicleCounts(this.queryParams)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  border-radius: 8px;
  height: 342px;
  position: relative;
  .choose-wrap{
    width: 240px;
    float: right;
    display: flex;
    margin: 24px 24px 0 0;
    &::v-deep .el-input__inner{
      border: none;
      font-size: 14px;
      color: #0059FF;
      text-align: right;
    }
    &::v-deep .el-select-dropdown__item.selected.radio{
      background-color:  #E7EFFD;
      &:hover{
        background-color:  #E7EFFD;
      }
    }
  }
  .graph {
    position: absolute;
    width: 100%;
    top: 70px;
  }
}

</style>
