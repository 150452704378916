<template>
  <div class="charge-item">
    <div class="circle"> <span :style="{borderColor: tagColor}"/> {{chargeTitle}}</div>
    <div class="number">
      <span class="money-symbol">{{$t('common.money')}}</span>
      <span class="widget-number">{{value}}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    chargeTitle: {
      type: String,
      default: '总计应收'
    },
    tagColor: {
      type: String,
      default: ' #00CAE0'
    },
    value: {
      type: [String, Number],
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.charge-item {
  display: inline-block;
  width: 220px;
  padding-left: 30px;
  margin: 33px 0;
}

.circle {
  position: relative;
  color: #666666;
  font-size: 16px;
  padding-left: 8px;
  span {
    position: absolute;
    left: -20px;
    top: 50%;
    transform: translate(-50%, -50%);
    display: block;
    content: ' ';
    width: 15px;
    height: 15px;
    border: 4px solid red;
    border-radius: 50%;
  }
}

.money-symbol {
  font-family: '微软雅黑';
}

.number {
  font-size: 40px;
}
</style>
