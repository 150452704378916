<template>
  <div>
    <div class="choose-wrap" :style="{width: hideDate ? 'initial' :'240px'}">
      <el-select v-model="dateValue" v-if="!hideDate">
        <el-option v-for="(item, i) in dateChoices" :key="i" :value="item.value" :label="item.name"></el-option>
      </el-select>
      <el-select v-model="parkId">
        <el-option v-for="(item, i) in parkingData" :key="i" :value="item.value" :label="item.name"></el-option>
      </el-select>
    </div>
    <div class="chart-wrap">
      <div ref="graph"></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import './reWrite.scss'
const dateChoices = [
  { name: '今日', value: 0 },
  { name: '昨日', value: 1 },
  { name: '近7日', value: 7 },
  { name: '近30日', value: 30 }
]
export default {
  props: {
    hideDate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dateChoices,
      dateValue: 0,
      parkId: ''
    }
  },
  computed: {
    parkingData () {
      return this.$store.getters['indexPage/parkingData']
    }
  },
  mounted () {
    this.handleDateChange(0)
  },
  methods: {
    // 处理停车库变化
    handleParkChange (value) {
      this.$emit('paramschange', { parkIds: value ? [value] : [] })
    },
    // 处理日期变化
    handleDateChange (value) {
      const date = new Date()
      const params = {}
      if (!value) {
        params.ttp = 'DAY'
        params.endTime = `${moment(date).format('YYYY-MM-DD HH:mm:ss')}`
        params.beginTime = `${moment(date).add(-value, 'days').format('YYYY-MM-DD')} 00:00:00`
      }
      if (value === 1) {
        params.ttp = 'DAY'
        params.endTime = `${moment(date).add(-1, 'days').format('YYYY-MM-DD')} 23:59:59`
        params.beginTime = `${moment(date).add(-value, 'days').format('YYYY-MM-DD')} 00:00:00`
      }
      if (value === 7 || value === 30) {
        params.ttp = 'CUSTOM'
        params.endTime = `${moment(date).format('YYYY-MM-DD')} 23:59:59`
        params.beginTime = `${moment(date).add(-(value - 1), 'days').format('YYYY-MM-DD')} 00:00:00`
      }
      this.$emit('paramschange', params)
    }
  },
  watch: {
    dateValue (val) {
      this.handleDateChange(val)
    },
    parkId (val) {
      this.handleParkChange(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.choose-wrap{
  width: 240px;
  float: right;
  display: flex;
  margin: 24px 24px 0 0;
  &::v-deep .el-input__inner{
    border: none;
    font-size: 14px;
    color: #0059FF;
    text-align: right;
  }
}
</style>
