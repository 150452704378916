<template>
  <div class="widget-shortcut-manage">
    <h3 class="widget-title">快捷入口</h3>
    <p class="desc-and-action mb-15">
      <span class="widget-desc">自定义添加常用功能的快捷入口，更高效地管理平台数据</span>
      <button class="manage-btn" @click="showDrawerFn" v-show="authShortList.length > 6">管理</button>
    </p>
    <CurrentShortcuts />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CurrentShortcuts from './CurrentShortcuts.vue'
import { eventBus } from '@/utils/util'

export default {
  components: {
    CurrentShortcuts
  },
  computed: {
    ...mapGetters('indexPage', ['authShortMap'])
  },
  data () {
    return {
      authShortList: []
    }
  },
  methods: {
    showDrawerFn () {
      eventBus.$emit('toggledrawer', true)
    }
  },
  watch: {
    authShortMap: {
      handler (val) {
        this.authShortList = Object.keys(val)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.widget-shortcut-manage {
  padding-top: 58px;
}

.desc-and-action {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: flex-end;
}

.manage-btn {
  height: 32px;
  padding: 0px 34px;
  background: #FFFFFF;
  border: 1px solid #EAEDF7;
  box-shadow: 0 2px 4px 0 rgba(28,41,90,0.04);
  border-radius: 2px;
  cursor: pointer;
}
</style>
