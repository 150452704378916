<template>
  <div class="current-shortcuts widget-card">
    <Shortcut v-for="list in authShortList" :key="list" :short-data="authShortMap[list]"/>
    <Shortcut v-if="authShortList.length<9 && allAuthShortList.length > 6" :short-data="addBtnData" showModel="4"/>
  </div>
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'
import Shortcut from './Shortcut.vue'
import { defaultList, shortMap } from './config'
import { eventBus } from '@/utils/util'
const addBtnData = {
  text: '添加快捷操作',
  imageActive: require('@/assets/widget/add.png'),
  imageNormal: '',
  tab: '',
  url: '',
  name: 'add'
}
export default {
  components: {
    Shortcut
  },
  computed: {
    ...mapState('GLOBAL', ['userInfo']),
    ...mapState('navigation', ['tabs']),
    ...mapGetters('indexPage', ['authShortMap'])
  },

  data () {
    return {
      addBtnData,
      initialShortList: defaultList, // 用户最初配置的可展示的列表【从未配置过那么就是用config文件中的defaultList值，非首次的情况则从localStorage中获取】
      allKeys: [], // 所有有权限访问的tab列表
      allAuthShortList: [], // 所有有权限访问的快捷入口列表
      authShortList: defaultList // 用户最终有权限展示的的快捷入口列表
    }
  },
  mounted () {
    this.invoke(this.tabs)
    // 如果右侧抽屉隐藏了，那么重新初始化快捷入口的展示
    eventBus.$on('toggledrawer', (val) => {
      if (!val) {
        this.initShortList()
      }
    })
  },
  methods: {
    ...mapMutations('indexPage', ['setAuthShortMap']),
    initShortList () {
      const userShortListMap = JSON.parse(localStorage.getItem('userShortListMap') || '{}')
      if (userShortListMap[this.userInfo.userName]) {
        this.initialShortList = userShortListMap[this.userInfo.userName]
        this.getAuthShortListFromShowList()
      } else {
        // 根据不同的用户保存
        localStorage.setItem('userShortListMap', JSON.stringify({
          ...userShortListMap,
          [this.userInfo.userName]: defaultList
        }))
      }
    },
    // 获取所有权限菜单列表的key
    getAuthKeys (tabs) {
      tabs.forEach(item => {
        const { key, menus } = item
        key && this.allKeys.push(key)
        if (menus && menus.length) {
          this.getAuthKeys(menus)
        }
      })
    },
    // 生成用户所有有权限展示的快捷入口对应的数据
    genAuthShortMap () {
      const newMap = {}
      defaultList.forEach(item => {
        if (this.allKeys.indexOf(shortMap[item].tab) > 0) {
          newMap[item] = shortMap[item]
        }
      })
      this.allAuthShortList = Object.keys(newMap)
      // console.log('用户所有有权限展示的快捷入口列表：', String(this.allAuthShortList))
      this.setAuthShortMap(newMap)
      this.getAuthShortListFromShowList()
    },
    // 从用户可展示的列表中，找出有权限项，作为最终的展示列表
    getAuthShortListFromShowList () {
      const arr = []
      this.initialShortList.forEach(item => { // 遍历用户可展示列表
        if (this.allAuthShortList.indexOf(item) > -1) { // 是否有权限
          arr.push(item)
        }
      })
      this.authShortList = arr
    },
    // 获取有权限tab列表和快捷入口数据两个方法聚合调用
    invoke (tabs) {
      if (tabs && tabs.length) {
        this.getAuthKeys(tabs)
      } else {
        this.getAuthKeys([])
      }
      this.genAuthShortMap()
    }
  },
  watch: {
    tabs: {
      handler (val) {
        this.invoke(val)
      },
      deep: true
    },
    'userInfo.userName' () {
      this.initShortList()
    }
  }
}
</script>

<style lang="scss" scoped>
.current-shortcuts {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 20px;
  border-radius: 8px;
  background: white;
}
</style>
