<template>
  <div class="widget-parklotspace">
    <h3 class="widget-title mb-10">停车场余位</h3>
    <div class="content widget-card">
      <div class="choose-parent">
        <date-and-park-choose @paramschange="handleParamsChange" :hide-date="true"/>
      </div>
      <div class="total">
        <img :src="require('@/assets/widget/cheliang_blue.svg')" height="70" alt="">
        <div class="total-text">
          <p class="number widget-number">{{parkData.totalPlace}}</p>
          <p class="desc">车辆总车位数</p>
        </div>
      </div>
      <div class="progress">
        <Progress :rate="carRate"/>
      </div>
      <div class="progress-desc mt-25">
        <ProgressDescItem :count="curCars"/>
        <ProgressDescItem text="剩余车位数" :count="parkData.leftPlace"/>
      </div>
    </div>
  </div>
</template>

<script>
import Progress from './Progress.vue'
import ProgressDescItem from './ProgressDescItem.vue'
import DateAndParkChoose from '../ChargeSummary/DateAndParkChoose'
import { getCarLocations, inRecordGetCount } from '@/api/index'

export default {
  components: {
    Progress,
    ProgressDescItem,
    DateAndParkChoose
  },

  data () {
    return {
      parkData: {
        totalPlace: 0, // 总车位数
        leftPlace: 0 // 总剩余车位数
      },
      curCars: 0
    }
  },

  computed: {
    carRate () {
      if (this.parkData.totalPlace && this.curCars) {
        return this.curCars / this.parkData.totalPlace
      } else {
        return 0
      }
    }
  },

  methods: {
    // 获取余位情况
    async getLocations (parkIds) {
      const { data, code } = await getCarLocations(parkIds)
      if (code === '0') {
        this.parkData = this.dealAllData(data && data.list ? data.list : [])
      }
    },

    // 获取在场数量
    async getInCount (parkIds) {
      const { data } = await inRecordGetCount({ parkIds })
      const result = data.list.reduce((pre, cur) => {
        return pre + cur.count
      }, 0)
      this.curCars = result
    },

    // 处理参数改变
    handleParamsChange (params) {
      this.getLocations(params.parkIds)
      this.getInCount(params.parkIds)
    },
    // 处理所有的停车场余位【将所有停车场的车位汇总】
    dealAllData (arr) {
      const data = {
        totalPlace: 0, // 总车位数
        leftPlace: 0 // 总剩余车位数
      }
      if (!arr.length) {
        return
      }
      arr.forEach(item => {
        const { leftPlace, totalPlace } = item
        data.leftPlace += leftPlace
        data.totalPlace += totalPlace
      })
      return data
    }
  },

  mounted () {
    this.getLocations('')
    this.getInCount('')
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  height: 342px;
  border-radius: 8px;
  position: relative;
  .choose-parent{
    width: 160px;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.total {
  display: flex;
  flex-flow: row nowrap;
  padding: 40px;
}

.total-text {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
}

.number {
  font-size: 40px;
}

.desc {
  font-size: 16px;
  color: #999999;
}

.progress, .progress-desc {
  width: 316px;
}

.progress-desc {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
</style>
