<template>
  <div class="traffic-volume-chart">

  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  props: {
    isSinglePark: {
      type: Boolean,
      default: false
    },
    chartData: {
      type: Array,
      default: () => []
    },
    chartXAxis: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      noObserve: Object.seal({
        chart: null
      })
    }
  },

  methods: {
    // 渲染图表
    renderChart (data) {
      const option = {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: data.titles
        },
        yAxis: {
          type: 'value'
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
            offset: 0,
            color: 'rgba(76,217,100,0.7)'
          }, {
            offset: 1,
            color: 'rgba(76,217,100,0.05)'
          }])
        },
        series: [{
          symbol: 'emptyCircle',
          symbolSize: 10,
          lineStyle: {
            color: 'rgba(74,212,97,1)',
            width: 3
          },
          itemStyle: {
            color: 'rgba(51,102,255,1)',
            borderWidth: 4
          },
          data: data.values,
          type: 'line',
          areaStyle: {}
        }]
      }
      const chart = this.noObserve.chart = echarts.init(this.$el)
      chart.setOption(option)
    },
    // 解析父级传进来的数据，获取图表所需的数据
    parseData (arr) {
      let values = []
      if (!this.isSinglePark) {
        const valueLength = arr[0].totalList.length
        for (let i = 0; i < valueLength; i++) {
          let itemTotal = 0
          arr.forEach(item => {
            // values.push(item.totalList[0])
            itemTotal += item.totalList[i]
          })
          values.push(itemTotal)
        }
      } else {
        values = arr
      }
      return {
        titles: this.chartXAxis,
        values
      }
    }
  },

  mounted () {
  },
  watch: {
    chartData: {
      handler (val) {
        if (val.length) {
          this.renderChart(this.parseData(val))
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.traffic-volume-chart {
  width: 100%;
  height: 280px;
}
</style>
