<template>
  <div class="widget-card">
    <span class="data widget-number mb-20">
      {{summaryData.count || 0}}
    </span>
    <img :src="summaryData.img" height="48" alt="">
    <span class="desc-text mt-30">
      {{summaryData.name}}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    summaryData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.widget-card {
  border-radius: 4px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 28px 0;
  width: 173px;
  height: 247px;
}

.data {
  font-size: 56px;
}

.desc-text {
  color:  #666666;
}
</style>
