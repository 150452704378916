<template>
  <div class="shortcut" :class="{ 'shortcur-hover': showModel === '1' }" @click="handleJump">
    <!-- <img src="@/assets/widget/park_manage.png" height="54" alt="" /> -->
    <div class="shortcut-icon">
      <span
        class="blue"
        :style="{ backgroundImage: `url(${shortData.imageActive})` }"
      ></span>
      <span
        class="white"
        :style="{ backgroundImage: `url(${shortData.imageNormal})` }"
      ></span>
    </div>
    <span
      @click="handleDelete"
      class="operate-btn delete"
      v-if="showModel === '2'"
    ></span>
    <span
      @click="toggleChoose"
      class="operate-btn"
      :class="shortData.choosed ? 'choosed' : 'unchoosed'"
      v-if="showModel === '3'"
    ></span>
    <span class="shortcut-title">
      {{ shortData.text }}
    </span>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { eventBus } from '@/utils/util'
export default {
  props: {
    index: {
      // 组件的下标
      type: Number,
      default: -1
    },
    showModel: {
      // 展示模式【1：正常展示，2：添加面板中的“已添加”展示，3：添加面板中的“全部”展示】
      type: String,
      default: '1'
    },
    shortData: {
      // 快捷入口相关数据
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {}
  },
  mounted () {},
  methods: {
    ...mapMutations('navigation', ['setCurrentIndex']),
    // 处理快捷入口的点击跳转
    handleJump () {
      if (this.shortData.name === 'add') {
        eventBus.$emit('toggledrawer', true)
        return
      }
      if (this.showModel === '1') {
        const { tab } = this.shortData
        tab && this.setCurrentIndex(tab)
      }
    },
    // 处理快捷入口删除点击
    handleDelete () {
      this.$emit('delete', this.index)
    },
    // 处理快捷入口的选中/取消选中
    toggleChoose () {
      this.$emit('togglechoose', this.index)
    }
  }
}
</script>

<style lang="scss" scoped>
.shortcut {
  width: 134px;
  height: 134px;
  padding: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  .operate-btn{
    width: 21px;
    height: 21px;
    position: absolute;
    top: 0;
    right: 0;
  }
  .delete {
    background: url('../../../../assets/widget/delete.png') center/contain no-repeat;
  }
  .choosed{
    background: url('../../../../assets/widget/choosed.png') center/contain no-repeat;
  }
  .unchoosed{
    background: url('../../../../assets/widget/unchoosed.png') center/contain no-repeat;
  }
}
.shortcur-hover {
  &:hover {
    background: rgba(51, 102, 255, 1);
    box-shadow: 0 7px 13px 0 rgba(139, 161, 242, 0.5);
    color: white;
    .blue {
      display: none;
    }
    .white {
      display: inline-block;
    }
  }
}
.shortcut-icon {
  user-select: none;
  width: 54px;
  height: 54px;
  margin: 25px auto 10px;
  span {
    display: inline-block;
    width: 100%;
    height: 100%;
    background: url(#) center/54px 54px no-repeat;
  }
  .white {
    display: none;
  }
}

.shortcut-title {
  margin-top: 10px;
}
</style>
