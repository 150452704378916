<template>
  <div class="drawer-container">
    <div class="mask" v-show="showDrawer"></div>
    <transition :name="showDrawer?'drawer-left':'drawer-right'">
      <div class="drawer-wrap" v-show="showDrawer">
        <div class="title">添加快捷入口</div>
        <div class="choose-panel">
          <div class="choose-title">
            已添加
            <span>（{{choosedCount}}/9）快捷操作不可少于6个</span>
          </div>
          <div class="choose-list-wrap">
            <Shortcut
              v-for="(list, index) in choosedList"
              :key="list.name"
              :short-data="list"
              showModel="2"
              :index="index"
              @delete="handleDelete"
            />
          </div>
        </div>
        <div class="choose-panel unchoose-panel">
          <div class="choose-title">全部</div>
          <div class="choose-list-wrap">
            <Shortcut
              v-for="(list, index) in unchoosedList"
              :key="list.name"
              :short-data="list"
              showModel="3"
              :index="index"
              @togglechoose="handleChoose"
            />
          </div>
        </div>
        <div class="btn-wrap">
          <span class="confirm" @click="handleConfirm">确定</span>
          <span class="cancel" @click="handleCancel">取消</span>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import Shortcut from './Shortcut'
import { shortMap } from './config'
import { Message } from 'hui'
import { eventBus } from '@/utils/util'
export default {
  components: {
    Shortcut
  },
  computed: {
    ...mapGetters('indexPage', ['authShortMap']),
    ...mapState('GLOBAL', ['userInfo'])
  },
  data () {
    return {
      choosedList: [], // 已选中的快捷入口列表
      unchoosedList: [], // 未选中的快捷入口列表
      shortMap, // 最初快捷入口数据
      choosedCount: 0, // 已选中的快捷入口数量
      showDrawer: false,
      allAuthShortList: [] // 所有有权限访问的快捷入口列表
    }
  },
  mounted () {
    this.allAuthShortList = Object.keys(this.authShortMap)
    this.initShortList()
    this.showDrawer = true
  },
  methods: {
    // 初始化列表
    initShortList () {
      const userShortListMap = JSON.parse(localStorage.getItem('userShortListMap') || '{}')
      let shortList = userShortListMap[this.userInfo.userName] || [] // 获取用户最初的快捷入口列表
      shortList = this.getAuthShortList(shortList) // 获得用户最终有权限展示的列表
      const all = [...this.allAuthShortList]
      const choosed = []
      const unchoosed = []
      if (shortList) {
        shortList.forEach(item => {
          choosed.push({ ...shortMap[item], choosed: true })
          // 从全量列表中删除以选中，得到未选中的列表
          const index = all.indexOf(item)
          if (index > -1) {
            all.splice(index, 1)
          }
        })
        all.forEach(item => {
          unchoosed.push({ ...shortMap[item], choosed: false })
        })
        this.choosedList = choosed
        this.unchoosedList = unchoosed
        this.choosedCount = this.choosedList.length
      }
    },
    // 确认
    handleConfirm () {
      if (this.choosedCount < 6) {
        Message.error('最少添加6个快捷入口')
        return
      }
      const arr = []
      this.choosedList.forEach(item => {
        arr.push(item.name)
      })
      this.unchoosedList.forEach(item => {
        if (item.choosed) {
          arr.push(item.name)
        }
      })
      const userShortListMap = JSON.parse(localStorage.getItem('userShortListMap') || '{}')
      localStorage.setItem('userShortListMap', JSON.stringify({
        ...userShortListMap,
        [this.userInfo.userName]: arr
      }))
      eventBus.$emit('toggledrawer', false)
    },
    // 取消
    handleCancel () {
      this.showDrawer = false
      setTimeout(() => {
        eventBus.$emit('toggledrawer', false)
      }, 400)
    },
    // 处理快捷入口删除
    handleDelete (index) {
      const data = this.choosedList.splice(index, 1)[0] // 删除已选择的快捷入口
      data.choosed = false
      this.unchoosedList.push(data) // 将其添加到未选择列表中
      this.choosedCount-- // 并更新已选中数量
    },
    // 处理快捷入口选中/取消选中
    handleChoose (index) {
      const data = this.unchoosedList[index]
      if (this.choosedCount >= 9) {
        Message.error('最多添加9个快捷入口')
        return
      }
      data.choosed = !data.choosed
      // 更新已选中数量
      data.choosed ? this.choosedCount++ : this.choosedCount--
    },
    // 找出初始快捷入口中用户有权限的项【供展示】
    getAuthShortList (shortList) {
      const arr = []
      shortList.forEach(item => {
        if (this.allAuthShortList.indexOf(item) > -1) {
          arr.push(item)
        }
      })
      return arr
    }
  }
}
</script>
<style lang="scss" scoped>
.drawer-container {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0;
  left: 0;
  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(27, 22, 22, 0.4);
  }
  .drawer-wrap {
    height: 100%;
    width: 780px;
    position: absolute;
    background-color: rgb(245, 247, 249);
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    .title {
      height: 72px;
      border-bottom: 1px solid #f5f7f9;
      font-size: 24px;
      line-height: 72px;
      margin-bottom: 13px;
      background-color: #fff;
      padding-left: 40px;
    }
  }
  .choose-panel {
    background-color: #fff;
    padding: 20px 0 20px 40px;
    margin-bottom: 10px;
  }
  .unchoose-panel{
    flex: 1;
  }
  .choose-title {
    height: 24px;
    line-height: 24px;
    font-size: 18px;
    color: #333;
    padding-left: 12px;
    position: relative;
    margin-bottom: 17px;
    &::before {
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
      width: 4px;
      background-color: #0059ff;
      border-radius: 2px;
      height: 16px;
    }
    span {
      font-size: 14px;
      color: #666;
    }
  }
  .choose-list-wrap {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    &::v-deep .shortcut {
      margin: 0 10px 10px 0;
    }
  }
  .btn-wrap{
    height: 56px;
    display: flex;
    align-items: center;
    place-content: flex-end;
    padding-right: 40px;
    cursor: pointer;
    span{
      width: 96px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      margin-right: 8px;
    }
  }
  .confirm{
    background-color: rgba(0,89,255,0.60);
    color: #fff;
    &:hover{
      background-color: rgba(58, 126, 252, 0.6);
    }
  }
  .cancel{
    border: 1px solid rgba(0,0,0,0.20);
    border-radius: 2px;
    background-color: #fff;
    color: rgba(0,0,0,0.70);
  }

  .drawer-right-enter-active,
  .drawer-right-leave-active,
  .drawer-left-enter-active,
  .drawer-left-leave-active {
    will-change: transform;
    transition: all 400ms;
    position: absolute;
  }
  .drawer-right-enter {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  .drawer-right-leave-active {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  .drawer-left-enter {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  .drawer-left-leave-active {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}
</style>
