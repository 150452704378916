<template>
  <div class="desc-item">
    <div class="point" :style="{backgroundColor: text === '在场车辆数'? '#3366FF' : '#EFEFF5'}"></div>
    <span class="ml-10">{{text}} {{count}}</span>
  </div>
</template>

<script>
export default {
  props: {
    count: {
      type: Number,
      default: 0
    },
    text: {
      type: String,
      default: '在场车辆数'
    }
  }
}
</script>

<style lang="scss" scoped>
.desc-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.point {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
</style>
