<template>
  <div class="widget-charge-summary">
    <h3 class="widget-title mb-10">收费概况</h3>
    <div class="content widget-card">
      <div class="left-content">
        <el-row>
          <ChargeItem chargeTitle="总计实收" :value="incomeData.totalIncome"/>
          <ChargeItem chargeTitle="总计支出" tagColor="#7600E0" :value="incomeData.totalExpense"/>
        </el-row>
        <el-row>
          <ChargeItem chargeTitle="微信实收" tagColor="#F35129" :value="incomeData[2]?incomeData[2].fee:0"/>
          <ChargeItem chargeTitle="支付宝实收" tagColor="#FCCA1E" :value="incomeData[1]?incomeData[1].fee:0"/>
          <ChargeItem chargeTitle="现金实收" tagColor="#2C5EF3" :value="incomeData[0]?incomeData[0].fee:0"/>
        </el-row>
      </div>
      <div class="right-content">
        <ChargeChart :chart-data="incomes" :total="incomeData.totalIncome" @paramschange="getCharge"/>
      </div>
    </div>
  </div>
</template>

<script>
import ChargeChart from './ChargeChart.vue'
import ChargeItem from './ChargeItem.vue'
import { fetchVehicleParams, fetchCashStatsPageData } from '@/api/index'

export default {
  components: {
    ChargeChart,
    ChargeItem
  },
  data () {
    return {
      payMap: {}, // 支付方式映射
      chargeParams: { // 收费概况请求参数
        parkIds: [],
        flag: 3,
        format: 'excel',
        beginTime: '',
        endTime: ''
      },
      incomes: [
        // { value: 501, name: 0 },
        // { value: 50, name: 1 },
        // { value: 800, name: 2 }
      ], // 收入数据【供chart使用】
      incomeData: {
        totalExpense: 0,
        totalIncome: 0
      } // 收入数据【供左侧ChargeItem展示】
    }
  },
  mounted () {
    this.getPayTypes()
  },
  methods: {
    // 获取收费概况
    async getCharge (params) {
      this.chargeParams = { ...this.chargeParams, ...params }
      const { data } = await fetchCashStatsPageData(this.chargeParams)
      const incomes = []
      const incomeData = {
        totalExpense: 0,
        totalIncome: 0
      }
      data.incomes.forEach(item => {
        // 查找的到的支付方式才进行处理
        if (this.payMap[item.modeKey]) {
          item.name = this.payMap[item.modeKey] || ''
          item.value = item.fee || 0
          incomeData[item.modeKey] = item
        }
      })
      incomes.push(incomeData[2] || {
        fee: 0,
        modeKey: 2,
        name: '微信支付',
        value: 0
      })
      incomes.push(incomeData[1] || {
        fee: 0,
        modeKey: 1,
        name: '支付宝支付',
        value: 0
      })
      incomes.push(incomeData[0] || {
        fee: 0,
        modeKey: 0,
        name: '现金支付',
        value: 0
      })
      this.incomes = incomes
      incomeData.totalExpense = data.totalExpense
      incomeData.totalIncome = data.totalIncome
      this.incomeData = incomeData
    },
    // 获取支付方式
    getPayTypes () {
      const payMap = JSON.parse(localStorage.getItem('payTypeMap') || '{}')
      if (Object.keys(payMap).length) {
        this.payMap = payMap
        this.getCharge()
        return
      }
      fetchVehicleParams({
        types: ['int_payType']
      }).then(res => {
        const { code, data } = res
        if (code === '0') {
          const result = data.int_payType
          const payMap = {}
          result.map((payType) => {
            payMap[payType.intKey] = payType.text
          })
          this.payMap = payMap
          localStorage.setItem('payTypeMap', JSON.stringify(payMap))
          this.getCharge()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  border-radius: 4px;
  display: flex;
  flex-flow: row nowrap;
  height: 320px;
}

.left-content {
  flex: 1 0 auto;
  padding: 20px 0px 20px 80px;
}
</style>>
