<template>
  <div class="dashboard">
    <!-- <div class="dashboard-body">
      <div class="space-between">
        <div style="width: 724px">
          <el-row>
            <Weelcome />
          </el-row>
          <el-row class="mt-20">
            <ParkingLotSummary />
          </el-row>
        </div>
        <div class="ml-25" style="width: 450px">
          <ShortcutManage />
        </div>
      </div>

      <div class="mt-20">
        <div>
          <ChargeSummary :parking-data="getParkingData"/>
        </div>
      </div>

      <div class="mt-20 space-between">
        <div style="width: 422px;">
          <ParkingLotSpaceInfo />
        </div>
        <div style="width: 754px;">
          <TrafficVolumeTrend/>
        </div>
      </div>
    </div>
    <drawer v-if="showDrawer"/> -->
  </div>
</template>

<script>
import ShortcutManage from './widgets/ShortcutManage/ShortcutManage.vue'
import Weelcome from './widgets/Weelcome/Weelcome.vue'
import ParkingLotSummary from './widgets/ParkingLotSummary/ParkingLotSummary.vue'
import ChargeSummary from './widgets/ChargeSummary/ChargeSummary.vue'
import ParkingLotSpaceInfo from './widgets/ParkingLotSpaceInfo/ParkingLotSpaceInfo.vue'
import TrafficVolumeTrend from './widgets/TrafficVolumeTrend/TrafficVolumeTrend.vue'
import { mapActions } from 'vuex'
import Drawer from './widgets/ShortcutManage/Drawer'
import { eventBus } from '@/utils/util'

export default {
  components: {
    Weelcome,
    ShortcutManage,
    ParkingLotSummary,
    ChargeSummary,
    ParkingLotSpaceInfo,
    TrafficVolumeTrend,
    Drawer
  },

  data () {
    return {
      showDrawer: false
    }
  },
  computed: {
    parkingData () {
      return this.$store.getters['indexPage/parkingData']
    }
  },
  mounted () {
    // this.getParkingData(this)
    // // 监听抽屉展示隐藏事件
    // eventBus.$on('toggledrawer', (val) => {
    //   this.showDrawer = val
    // })
  },
  methods: {
    ...mapActions('indexPage', ['getParkingData'])
  },
  watch: {
    parkingData: {
      handler (val) {
        if (val.length === 1) { // 这里val内会自动添加一个“全部停车库”，等于1说明是首次，那么进入引导页面完成停车库的首次添加
          location.href = '/portal/guide'
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  min-height: 100%;
  padding: 10px 0;
  background: rgb(245 247 249);
}

.dashboard-body {
  margin: 0 auto;
  width: 1200px;
}

.space-between {
  display: flex;
  justify-content: space-between
}
</style>
