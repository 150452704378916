<template>
  <div class="progress">
    <div class="value" :style="{width: rate+'%'}"></div>
  </div>
</template>

<script>
export default {
  props: {
    rate: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.progress {
  border-radius: 8px;
  height: 16px;
  background: #EFEFF5;
  overflow: hidden;
}

.value {
  height: 100%;
  width: 30%;
  background: #3366FF;
}
</style>
